import type { PropType } from 'vue'
import { type Column, FlexRender, type Header } from '@tanstack/vue-table'
import { Skeleton } from '#components'
import type { DataTableVariants } from './composables'
import type { DataTableProps } from './types'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import Expander from './Expander'

export default defineComponent({
  props: {
    skeletonCount: {
      type: Number,
      required: true,
    },
    headers: {
      type: Array as PropType<Header<any, any>[]>,
      required: true,
    },
    variants: {
      type: Object as PropType<DataTableVariants>,
      required: true,
    },
    virtualScroller: {
      type: Object as PropType<DataTableProps['virtualScroller']>,
      default: undefined,
    },
    offsetHeader: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    //These are the important styles to make sticky column pinning work!
    //Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
    //View the index.css file for more needed styles such as border-collapse: separate
    const getCommonStyles = (column: Column<any> | undefined) => {
      const isPinned = column?.getIsPinned() ?? false
      const size = column?.getSize() ?? 150

      return {
        left:
          column && isPinned === 'left'
            ? `${column.getStart('left')}px`
            : undefined,
        right:
          column && isPinned === 'right'
            ? `${column.getAfter('right')}px`
            : undefined,
        position: isPinned ? 'sticky' : 'relative',
        width: `${size}px`,
        zIndex: isPinned ? 1 : 0,
      }
    }

    return () => (
      <table class={props.variants.table({ type: 'table' })}>
        <thead
          class={props.variants.thead({ type: 'table' })}
          style={{
            top: props.offsetHeader,
          }}
        >
          <tr class={props.variants.theadTr({ type: 'table' })}>
            {Array.from({ length: props.headers.length || 10 }, (_, elm) => {
              const header: Header<any, any> | undefined = props.headers[elm]
              const styles = getCommonStyles(header?.column)

              return (
                <th
                  key={elm}
                  class={props.variants.theadTh({
                    type: 'table',
                    isLastLeftPinnedColumn:
                      header?.column.getIsLastColumn('left'),
                    isFirstRightPinnedColumn:
                      header?.column.getIsFirstColumn('right'),
                  })}
                  style={styles}
                >
                  {header ? (
                    header?.column.id === 'select' ? (
                      <IndeterminateCheckbox disabled />
                    ) : header?.column.id === 'expander' ? (
                      <div style={{ height: '22px' }} />
                    ) : (
                      <FlexRender
                        render={header?.column.columnDef.header}
                        props={header.getContext()}
                      />
                    )
                  ) : (
                    <Skeleton height={'22px'} width={`${styles.width}`} />
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody class={props.variants.tbody({ type: 'table' })}>
          {Array.from({ length: props.skeletonCount }, (_, skeleton) => (
            <tr
              key={skeleton}
              class={props.variants.tbodyTr({ type: 'table' })}
              style={{
                height: `${props.virtualScroller?.estimateSize(skeleton)}px`,
              }}
            >
              {Array.from({ length: props.headers.length || 10 }, (_, elm) => {
                const column: Column<any> | undefined =
                  props.headers[elm]?.column
                const styles = getCommonStyles(column)

                return (
                  <td
                    key={elm}
                    class={props.variants.tbodyTd({
                      type: 'table',
                      isLastLeftPinnedColumn: column?.getIsLastColumn('left'),
                      isFirstRightPinnedColumn:
                        column?.getIsFirstColumn('right'),
                    })}
                    style={styles}
                  >
                    {column?.id === 'select' ? (
                      <IndeterminateCheckbox disabled />
                    ) : column.id === 'expander' ? (
                      <Expander class={'relative top-0.5'} disabled />
                    ) : (
                      <Skeleton height={'22px'} width={`${styles.width}`} />
                    )}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    )
  },
})
